<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

        <!-- image -->
        <component :is="imgUrl"></component>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
import store from '@/store/index'
import ErrorSVG from '@/assets/images/pages/error.svg';
import ErrorDarkSVG from '@/assets/images/pages/error-dark.svg';

import { BLink, BButton, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    ErrorSVG,
    ErrorDarkSVG
  },
  data() {
    return {}
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return ErrorDarkSVG
      }
      return ErrorSVG
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
